<template>
  <div  class="printMain body-bg">
    <!-- 导入excel -->
    <!-- <el-dialog :title="$t('i18nn_17a941b7c4c108fe')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
    <!-- <div class="top_action no_print"><el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button></div>
 -->
		<div class="printer-logo"><img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" /></div>
    <div style="" v-loading="loading_load">
      <div class="printer_con">
				<h1 class="printer_tit" style="">
					<div style="">
						<div>Transport Receipt List</div>
						<!-- <div v-if="!!$route.query.carTmsType">
							<span>Order Type：</span>
							<span v-if="'2'==$route.query.carTmsType">FBA转运</span>
							<span v-else-if="'1'==$route.query.carTmsType">{{$t('i18nn_34ffc91fe81fa585')}}</span>
							<span v-else-if="'3'==$route.query.carTmsType">{{$t('i18nn_81f6eaf23b9c56eb')}}</span>
							<span v-else>{{$route.query.carTmsType}}</span>
						</div> -->
						<!-- <strong>{{ priterData.cusName }}</strong> -->
					</div>
					
					<!-- <div>
						<barcode :code="priterData.inWhCode"></barcode>
					</div> -->
				</h1>
				
				<div style="display: flex;justify-content: space-between; align-items: center; ">
					
					<barcode :code="priterData.inWhCode" :options="{height:50}"></barcode>
					<div style="font-size: 12pt; text-align: right; word-break: break-all;white-space:normal; ">
						<div style="margin:5px 0;">Customer#&nbsp;{{priterData.cusName}}</div>
						<div style="margin:5px 0;">Warehouse#&nbsp;{{priterData.whNo}}</div>
						<div style="margin:5px 0;">Date#&nbsp;{{priterData.commitDate}}</div>
						<div style="margin:5px 0;">Type#&nbsp;<span>{{priterData.arriveWhWayName}}</span></div>
						<div style="margin:5px 0;">Order#&nbsp;<span>{{priterData.relationNo}}</span></div>
						
						<!-- <div>{{priterData.cusName}}</div> -->
					</div>
				</div>
				
        
        <!-- <h2 class="printer_tit2">
          <div class="titleItem">
            <span>order#</span>
            <strong>{{ priterData.sendNo }}</strong>
          </div>
          <div class="titleItem">
            <span>customer#</span>
            <strong>{{ priterData.cusName }}</strong>
          </div>
        </h2> -->
				<table class="simTable printerTable">
					<!-- <tr>
						<td>{{$t('i18nn_6f8f60e16e726ccc')}}</td>
						<th>
							<div v-if="!!$route.query.carTmsType">
								<span v-if="'2'==$route.query.carTmsType">FBA转运</span>
								<span v-else-if="'1'==$route.query.carTmsType">{{$t('i18nn_34ffc91fe81fa585')}}</span>
								<span v-else-if="'3'==$route.query.carTmsType">{{$t('i18nn_81f6eaf23b9c56eb')}}</span>
								<span v-else>{{$route.query.carTmsType}}</span>
							</div>
						</th>
						<td></td>
						<th></th>
					</tr> -->
					<!-- <tr>
						
						<td>客户(Customer)</td>
						<th>{{ priterData.cusName }}</th>
						<td>转运入库编号(No.)</td>
						<th>
							{{ priterData.inWhCode }}
						</th>
					</tr>
					<tr>
						<td>{{$t('i18nn_8221d63993501b1c')}}</td>
						<th>{{ priterData.arriveWhWayName }}</th>
						<td>{{$t('i18nn_7cebbad6657bdbf4')}}</td>
						<th>
							{{ priterData.arriveWhDate }}
						</th>
					</tr>
					<tr>
						<td>柜号/物流单号</td>
						<th>
							{{ priterData.relationNo }}
						</th>
						<td>{{$t('i18nn_696eca4149742942')}}</td>
						<th>{{ priterData.{{ $Utils.i18nKeyText(scope.row,'isShowSkuName') }} }}</th>
					</tr>
					<tr>
						<td>{{$t('i18nn_e137950d1897817e')}}</td>
						<th>
							{{ priterData.isLiveUploadName }}
						</th>
						<td></td>
						<th></th>
					</tr>
					<tr>
						<td>{{$t('i18nn_0ae081c3b8c4d4a1')}}</td>
						<td>
							{{ priterData.commitDate }}
						</td>
						<td>{{$t('i18nn_4647ba3fc60f0151')}}</td>
						<td>{{ priterData.inWhDate }}</td>
					</tr> -->
					
					<tbody class="text_center">
						<tr class="gray_bg">
							<th width="50">No.</th>
							<th width="50">Type</th>
							<th width="100">Mark</th>
							<th width="50">CTN</th>
							<th width="100">CTN(L*W*H)<br />(weight)</th>
							<th width="50">Pallets</th>
							<th width="100">SKU</th>
							<th width="50">CTN/PCS</th>
							<th width="50">Total PCS</th>
						</tr>
					</tbody>
					
					<tbody style="" class="text_center text_small">
						<tr v-for="(item, index) in priterData.recoreList" :key="index">
							<td>{{index+1}}</td>
							<td>{{item.inWhTypeNameEn}}</td>
							<td>{{item.ctnMark}}</td>
							<td>{{item.ctnCount}}</td>
							<td>{{item.ctnL}}*{{item.ctnW}}*{{item.ctnH}}<br />({{item.ctnWeight}})</td>
							<td>{{item.inWhPallte}}</td>
							<td>{{item.goodsSku}}</td>
							<td>{{item.ctnSkuCount}}</td>
							<td>{{item.skuTotal}}</td>
						</tr>
						<!-- <tr>
							<td>{{$t('b799f516e3940e3f')}}</td>
							<th>{{item.inWhTypeName}}</th>
							<td>箱内SKU</td>
							<td>{{item.goodsSku}}</td>
						</tr>
						<tr>
							<td>{{$t('i18nn_73fc38e67381ae73')}}</td>
							<th>{{item.ctnMark}}</th>
							<td>{{$t('i18nn_85a10d3e5ea0e103')}}</td>
							<td>{{item.ctnSkuCount}}</td>
						</tr>
						<tr>
							<td>{{$t('i18nn_bdc361ba04506136')}}</td>
							<th>{{item.ctnCount}}</th>
							<td>{{$t('i18nn_bad53577db0da2d3')}}</td>
							<td>{{item.skuTotal}}</td>
						</tr>
						<tr>
							<td>{{$t('i18nn_c8773d8d74202801')}}</td>
							<th>{{item.inWhPallte}}</th>
							<td>备注(Reamrk)</td>
							<td>{{item.remark}}</td>
						</tr> -->
					</tbody>
					
					<tbody>
						<tr>
							<td colspan="9">
								<span style="padding: 0 2mm;">Live Unload?</span>
								<strong style="padding: 0 4mm;">Yes</strong>
								<strong style="padding: 0 4mm;">No</strong>
								<!-- <span>
									{{ priterData.isLiveUploadName }}
								</span> -->
								
							</td>
						</tr>
					</tbody>
					
					<tbody >
						<tr>
							<!-- <td>Remark</td> -->
							<td colspan="9">
								<span>Remark#&nbsp;</span>
								<strong class="pre-text">{{priterData.remark}}</strong>
							</td>
						</tr>
						<!-- <tr>
							<td colspan="9">
								{{priterData.remark}}
							</td>
						</tr> -->
					</tbody>
					
				</table>
				
				<div class="tableFooter">
					<ul>
						<li>Unloaded Date</li>
						<li>Unloaded By</li>
						<li>Counted By</li>
					</ul>
				</div>
        <!-- <table class="simTable printerTable">
          <tr>
            <th width="50">No.</th>
						
            <th>SKU</th>
						<th>SKU Title</th>
						<th>CTN</th>
            <th>Qty</th>
            <th>warehouse</th>
            <th>location</th>
						<th>CNTR No</th>
          </tr>
          <tbody v-if="priterData.records">
            <tr v-for="(item, index) in priterData.records" :key="index" style="">
              <td>{{ index+1 }}</td>
							
              <td>{{item.goodsSku}}</td>
							<td>{{item.goodsNameEn}}&nbsp;|&nbsp;{{item.goodsName}}</td>
							<td>{{item.sendCTN}}</td>
              <td>{{item.plCount}}</td>
              <td>{{item.whNo}}</td>

              <td>{{item.place}}</td>
							<td>{{item.packingNo}}</td>
            </tr>
          </tbody>
        </table> -->
      </div>

      <!-- </div> -->
      <!-- </el-card> -->
    </div>

    <!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button></div> -->
    <!-- </el-dialog> -->
		
		<!-- 增值服务 -->
		
		<div style="height: 10px;" class="no_print"></div>
		<div v-show="isShowValueAdded" v-if="priterData.workOrderDtos && priterData.workOrderDtos.length>0">
			<div class="printer_break"></div>
			<whPrinterValueAddListSub :openTime="ValueAddOpenTime" :tableData="priterData.workOrderDtos"></whPrinterValueAddListSub>
		</div>
		
  </div>
</template>
<script>
// import Vue from 'vue';
// import Print from '@/plugins/print.js';
// Vue.use(Print); // 注册
import barcode from '@/components/Common/barcode.vue';
import whPrinterValueAddListSub from '@/printer/whPrinterValueAddListSub.vue';
export default {
  metaInfo() {
    return {
      title: this.priterData && this.priterData.relationNo?this.priterData.relationNo+'-Transport-Receipt-List':'HYTX-Transport-Receipt-List',
    }
  },
  props: {
  	isShowValueAdded: {
  	  type: Boolean
  	},
  },
  components: {
  	barcode,
		whPrinterValueAddListSub
  },
  data() {
    return {
      filterData: {
        sendId: ''
      },
      // listUrl: [],
      loading_load: false,
      priterData: {},
			ValueAddOpenTime:""
    };
  },
  // watch: {
  //   openTime: function(newVal, oldVal) {
  //     console.log('openTime');
  //     this.dialogFile = true;
  //     this.initData();
  //   }
  // },
  //创建时
  created() {
    // this.getPageData();
    if (this.$route.query && this.$route.query.sendId) {
      this.filterData.sendId = this.$route.query.sendId;
    }
    this.initData();
  },
  //编译挂载前
  mounted() {
    // this.initData();
  },
  methods: {
    initData() {
      this.getPageData();
    },
    //打印
    // printerAction() {
    //   window.print();
    //   // this.$print(this.$refs.print);
    // },
    //请求分页数据
    getPageData() {
      this.loading_load = true;

      this.$http
        .put(this.$urlConfig.WhTransferInWhPrintList, {
					id:this.filterData.sendId
				})
        .then(({ data }) => {
          console.log('列表，请求成功');
          console.log(data);
          
          this.loading_load = false;
          if (200 == data.code) {
            //表格显示数据
            this.priterData = data.data;
						//增值服务
						if(this.priterData && this.priterData.workOrderDtos && this.priterData.workOrderDtos.length>0){
							this.$emit('ValAddUpdate',{
								val: this.priterData.workOrderDtos.length,
								show: true,
							});
						} else {
							this.$emit('ValAddUpdate',{
								val: 0,
								show: true,
							});
						}
						this.ValueAddOpenTime = new Date().getTime();
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
          }
        })
        .catch(error => {
          console.log(error);
          console.log('列表，请求失败');
          this.$message.error(this.$t('hytxs0000030'));
          this.loading_load = false;
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
// @import '@/assets/css/printer.less'
@import url(../assets/css/printer.less);
</style>
